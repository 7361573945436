import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomsRateList: [],
  roomsSeasonalRateList: [],
  roomRateDetail: null,
  roomSeasonalRateDetail: null,
  accommodationList: [],
  roomCategoryTypes: [],
  suggestedHotelList: [],
  propertyId: null,
};
const roomRateSlice = createSlice({
  name: "roomRate",
  initialState: initialState,
  reducers: {
    roomCategoryTypesSuccess: (state, action) => {
      state.roomCategoryTypes = action.payload;
    },
    roomCategoryTypesFailed: (state, action) => {
      state.roomCategoryTypes = [];
    },
    roomsRateListSuccess: (state, action) => {
      state.roomsRateList = action.payload;
    },
    roomsRateListFailed: (state, action) => {
      state.roomsRateList = [];
    },
    suggestedHotelListSuccess: (state, action) => {
      state.suggestedHotelList = action.payload;
    },
    suggestedHotelListFailed: (state, action) => {
      state.suggestedHotelList = [];
    },
    roomsSeasonalRateListSuccess: (state, action) => {
      state.roomsSeasonalRateList = action.payload;
    },
    roomsSeasonalRateListFailed: (state, action) => {
      state.roomsSeasonalRateList = [];
    },
    accommodationListSuccess: (state, action) => {
      state.accommodationList = action.payload;
    },
    accommodationListFailed: (state, action) => {
      state.accommodationList = [];
    },
    roomRateDetailSuccess: (state, action) => {
      state.roomRateDetail = action.payload;
    },
    roomRateDetailFailed: (state, action) => {
      state.roomRateDetail = null;
    },
    roomSeasonalRateDetailSuccess: (state, action) => {
      state.roomSeasonalRateDetail = action.payload;
    },
    roomSeasonalRateDetailFailed: (state, action) => {
      state.roomSeasonalRateDetail = null;
    },
    propertyIdSuccess: (state, action) => {
      state.propertyId = action.payload;
    },
    propertyIdFailed: (state, action) => {
      state.propertyId = "";
    },
  },
});

export const {
  roomCategoryTypesSuccess,
  roomCategoryTypesFailed,
  roomsRateListSuccess,
  roomsRateListFailed,
  suggestedHotelListFailed,
  suggestedHotelListSuccess,
  roomRateDetailSuccess,
  roomRateDetailFailed,
  accommodationListSuccess,
  accommodationListFailed,
  roomSeasonalRateDetailSuccess,
  roomSeasonalRateDetailFailed,
  roomsSeasonalRateListSuccess,
  roomsSeasonalRateListFailed,
  propertyIdSuccess,
  propertyIdFailed,
} = roomRateSlice.actions;
export default roomRateSlice.reducer;
