import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rowsPerPageJsonData } from "../../utils/JsonData";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from "@mui/icons-material/Sync";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../../layout/Loader";
import {
  roomCategoryTypesFailed,
  roomCategoryTypesSuccess,
} from "../../Reducer/roomRateSlice";
import { connect } from "react-redux";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { createRecord, fetchList } from "../../apis/services/CommonApiService";
import moment from "moment";
import { toast } from "react-toastify";
import { createRatesAndAvailability } from "../../apis/services/ChannelManagerApiService";
import ConfirmDialog from "../../shared/components/ConfirmDialog";
import { getLanguageCode } from "../../utils/helper";

const AvailabilityOta = (props) => {
  const {
    roomCategoryTypes,
    roomCategoryTypesSuccess,
    roomCategoryTypesFailed,
  } = props;
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hotelID, setHotelID] = useState("");

  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(89, "days").format("YYYY-MM-DD"),
    room_type_id: 0,
  });

  const getPropertyId = async () => {
    const userHotelID = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))["hotel_id"]
      : 0;
    const result = await fetchList(
      ApiEndPoints.GET_HOTEL_DETAILS + userHotelID
    );

    if (result?.status === 200) {
      setHotelID(result?.data?.property_id);
    } else {
      console.log(result.message.response + ", please check!");
    }
  };

  const getRoomData = async () => {
    // setLoading(true)
    const resultRe = await fetchList(ApiEndPoints.ROOM_CATEGORY_SELECT_LIST);
    const RoomData = [];
    if (resultRe?.status === 200) {
      for (var j in resultRe.data) {
        RoomData.push({
          id: resultRe.data[j].id,
          // title: resultRe.data[j].room_name,
          type: resultRe.data[j]?.room_type_short_code,
          type_id: resultRe.data[j].id,
          max_occupancy: resultRe.data[j]?.max_occupancy,
          // housekeeping: resultRe.data[j]?.houseKeepingStatus?.status,
        });
      }
      if (!(JSON.stringify(RoomData) === JSON.stringify(roomCategoryTypes))) {
        roomCategoryTypesSuccess(RoomData);
      }
    } else {
      roomCategoryTypesFailed();
    }
  };

  useEffect(() => {
    // getRoomData();
    getPropertyId();
  }, []);

  const handleGetAllbooking = async () => {
    updateRatesDatagridOptions("loading", true);

    const result = await fetchList(
      ApiEndPoints.GET_OTA_RATES_OVERVIEW +
        `?start_date=${searchFilters.startDate}&end_date=${
          searchFilters.endDate
        }&ota_code=${[0]}`
    );

    const resultAvail = await fetchList(
      ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
        `?start_date=${searchFilters.startDate}&end_date=${searchFilters.endDate}`
    );

    const otaAvail = await createRecord(
      {
        hotelId: hotelID,
        startDate: searchFilters.startDate,
        endDate: searchFilters.endDate,
      },
      ApiEndPoints.OTA_AVAILABILITY_INVENTORY
    );

    let availabilityOtaData = [];
    if (result?.status === 200) {
      availabilityOtaData = result?.data;
    } else {
      updateRatesDatagridOptions("loading", false);
    }

    let availabilityData = [];
    if (resultAvail?.status === 200) {
      availabilityData = resultAvail?.data;
    } else {
      updateRatesDatagridOptions("loading", false);
    }

    let otaAvailInv = [];
    if (otaAvail?.status === 200) {
      otaAvailInv = otaAvail?.data;
    } else {
      toast?.error(t(otaAvail?.message?.Errors?.ShortText));
      updateRatesDatagridOptions("loading", false);
    }

    let dateArray = [];

    availabilityData = availabilityData?.map((item) => ({
      ...item,
      cate_type: roomCategoryTypes?.find((itemR) => item?.catId === itemR?.id)
        ?.type,
    }));

    availabilityOtaData = availabilityOtaData?.map((item) => ({
      ...item,
      cate_type: roomCategoryTypes?.find(
        (itemR) => item?.category_id === itemR?.id
      )?.type,
    }));

    for (let i in otaAvailInv) {
      for (let j in otaAvailInv[i]) {
        let filAvailData = availabilityData?.find(
          (item) => item?.date === i && item?.cate_type === j
        );
        let filOtaAvailData = availabilityOtaData?.find(
          (item) => item?.date === i && item?.cate_type === j
        );
        // console.log(i, j,j !== "", filAvailData, filOtaAvailData, (filAvailData && filOtaAvailData) , 156);

        if (j !== "" &&
          (+filAvailData?.availableRooms < +filOtaAvailData?.availability ||
          +filOtaAvailData?.availability !== +otaAvailInv[i][j]?.inventory)
        ) {
          if (
            searchFilters?.room_type_id === 0 ||
            searchFilters?.room_type_id === j
          ) {
            dateArray?.push({
              id: i + j,
              date: i,
              room_type: j,
              inventory: filAvailData?.availableRooms,
              ota_inventory: filOtaAvailData?.availability,
              exact_ota_inventory: otaAvailInv[i][j]?.inventory,
              ota_with_exact_inventory:
                filOtaAvailData?.availability +
                "/" +
                otaAvailInv[i][j]?.inventory,
            });
          }
        }
      }

      // console.log(dateArray,187);
      updateRatesDatagridOptions("totalRows", dateArray?.length);
      updateRatesDatagridOptions("rows", dateArray);
      updateRatesDatagridOptions("loading", false);

      // console.log( dateArray, 119);
    }
  };

  useEffect(() => {
    if (hotelID !== "") {
      handleGetAllbooking();
    }
  }, [hotelID, 
    searchFilters
  ]);

  const handleGetAndFixAll = async () => {
    setConfirmFixAll(false);
    if (ratesDatagridOptions?.rows?.length === 0) {
      toast.error(t("There is not any availability discrepancy"));
      return;
    }
    setLoading(true);

    const result = await fetchList(
      ApiEndPoints.GET_OTA_RATES_OVERVIEW +
        `?start_date=${searchFilters.startDate}&end_date=${
          searchFilters.endDate
        }&ota_code=${[0]}`
    );

    const resultAvail = await fetchList(
      ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
        `?start_date=${searchFilters.startDate}&end_date=${searchFilters.endDate}`
    );

    const otaAvail = await createRecord(
      {
        hotelId: hotelID,
        startDate: searchFilters.startDate,
        endDate: searchFilters.endDate,
      },
      ApiEndPoints.OTA_AVAILABILITY_INVENTORY
    );

    let availabilityOtaData = [];
    if (result?.status === 200) {
      availabilityOtaData = result?.data;
    } else {
      setLoading(false);
    }

    let availabilityData = [];
    if (resultAvail?.status === 200) {
      availabilityData = resultAvail?.data;
    } else {
      setLoading(false);
    }

    let otaAvailInv = [];
    if (otaAvail?.status === 200) {
      otaAvailInv = otaAvail?.data;
    } else {
      setLoading(false);
    }

    let dateArray = [];

    availabilityData = availabilityData?.map((item) => ({
      ...item,
      cate_type: roomCategoryTypes?.find((itemR) => item?.catId === itemR?.id)
        ?.type,
    }));

    availabilityOtaData = availabilityOtaData?.map((item) => ({
      ...item,
      cate_type: roomCategoryTypes?.find(
        (itemR) => item?.category_id === itemR?.id
      )?.type,
    }));

    for (let i in otaAvailInv) {
      for (let j in otaAvailInv[i]) {
        let filAvailData = availabilityData?.find(
          (item) => item?.date === i && item?.cate_type === j
        );
        let filOtaAvailData = availabilityOtaData?.find(
          (item) => item?.date === i && item?.cate_type === j
        );

        if (
          (+filAvailData?.availableRooms < +filOtaAvailData?.availability ||
            +filOtaAvailData?.availability !== +otaAvailInv[i][j]?.inventory) &&
          filOtaAvailData?.availability
        ) {
          if (
            searchFilters?.room_type_id === 0 ||
            searchFilters?.room_type_id === j
          ) {
            dateArray?.push({
              closed: 0,
              closedonarrival: 0,
              closedondeparture: 0,
              extraadultrate: 0,
              extrachildrate: 0,
              maximumstay: 0,
              minimumstay: 0,
              price: [{ NumberOfGuests: "", value: "" }],
              rate: [{ rateplanid: "" }],
              roomid: j,
              sendAllData: 2,
              showDate: 0,
              value: i,
              roomstosell: filAvailData?.availableRooms,
            });
          }
        }
      }
      // console.log( dateArray, 119);
    }

    if (dateArray?.length === 0) {
      toast.error(t("There is not any availability discrepancy"));
      setLoading(false);
      return;
    }

    let payload = {
      hotelid: hotelID,
      rooms: dateArray,
    };
    const resultCreate = await createRatesAndAvailability(payload);
    if (resultCreate?.status === 200) {
      toast.success(t(resultCreate.message));

      setLoading(false);
      handleGetAllbooking();
    } else if (resultCreate === undefined) {
      setLoading(false);
      toast.error(t(resultCreate.message));
    } else {
      setLoading(false);
      if (resultCreate?.message?.Errors?.length > 0) {
        toast.error(t(resultCreate.message.Errors[0].ShortText));
      } else {
        toast.error(t(resultCreate.message));
      }
    }
  };

  const [confirmFixAll, setConfirmFixAll] = useState(false);
  const [ratesDatagridOptions, setRatesDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 50,
    page: 1,
  });

  const updateRatesDatagridOptions = (k, v) =>
    setRatesDatagridOptions((prev) => ({ ...prev, [k]: v }));

  const ratesDatagridColumns = [
    // { field: "id", headerName: t("Id"), flex: 1 },
    { field: "date", headerName: t("Date"), flex: 1 },
    {
      field: "room_type",
      headerName: t("Room Type"),
      flex: 1,
    },
    {
      field: "inventory",
      headerName: t("Inventory"),
      flex: 1,
    },
    {
      field: "ota_with_exact_inventory",
      headerName: t("Overview/Exact OTA Inventory"),
      flex: 2,
    },
    // {
    //   field: "ota_inventory",
    //   headerName: t("Ota Overview Inventory"),
    //   flex: 1,
    // },
    // {
    //   field: "exact_ota_inventory",
    //   headerName: t("Exact Ota Inventory"),
    //   flex: 1,
    // },
  ];



  return (
    <div className="main-layout-background h-auto">
      {loading && <Loader />}
      <div className="d-flex mb-2 pr-2 justify-content-between align-items-center">
        <Grid container spacing={2} className="mb-2" alignItems="flex-start">
          {/* <Grid item lg={4}>
            <FormControl fullWidth>
              <TextField
                label={t("Search Hotel Name")}
                //   onChange={(event) => {
                //     handleRateSearch(event);
                //   }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid> */}
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <FormControl fullWidth>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500 !important",
                }}
              >
                {t("Start Date")}
              </Typography>
              <TextField
                type="date"
                value={searchFilters.startDate}
                onChange={(e) => {
                  setSearchFilters((prevState) => ({
                    ...prevState,
                    startDate: e.target.value,
                    endDate: moment(
                      moment(e.target.value, "YYYY-MM-DD").add({ days: 89 })
                    ).format("YYYY-MM-DD"),
                  }));
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            
          >
            <FormControl fullWidth>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500 !important",
                }}
              >
                {t("End Date")}
              </Typography>
              <TextField
                type="date"
                value={searchFilters.endDate}
                onChange={(e) => {
                  setSearchFilters((prevState) => ({
                    ...prevState,
                    endDate: e.target.value,
                  }));
                }}
                inputProps={{
                  max: moment(
                    moment(searchFilters.startDate, "YYYY-MM-DD").add({
                      days: 89,
                    })
                  ).format("YYYY-MM-DD"),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl fullWidth>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500 !important",
                }}
              >
                {t("Room Type")}
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchFilters.room_type_id}
                //label="Room Status"

                onChange={(e) =>
                  setSearchFilters({
                    ...searchFilters,
                    room_type_id: e.target.value,
                  })
                }
              >
                <MenuItem value={0}>{t("Select Room Type")}</MenuItem>
                {roomCategoryTypes?.map((data) => (
                  <MenuItem value={data.type} key={data.type}>
                    {data.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12} className="pt-5">
            <Button
              variant="outlined"
              // className="m-1"
              sx={{
                border: "1px solid #BFBFBF",
                marginRight: "10px",
                padding: "14px",
                borderRadius: "12px",
              }}
              onClick={() => handleGetAllbooking()}
            >
              <SyncIcon sx={{ fontSize: "18px" }} />
            </Button>
            <Button
              size="large"
              variant="contained"
              disableElevation
              className="comman-button"
              color="primary"
              onClick={() => setConfirmFixAll(true)}
            >
              {t("Fix All")}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div>
        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          pagination
          paginationMode={"client"}
          loading={ratesDatagridOptions.loading}
          rowCount={ratesDatagridOptions.totalRows}
          rowsPerPageOptions={ratesDatagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={ratesDatagridOptions.rows}
          columns={ratesDatagridColumns}
          page={ratesDatagridOptions.page - 1}
          pageSize={ratesDatagridOptions.pageSize}
          checkboxSelection={true}
          onPageChange={(newPage) => {
            setRatesDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            console.log("page size", pageSize);
            updateRatesDatagridOptions("page", 1);
            updateRatesDatagridOptions("pageSize", pageSize);
          }}
          sx={{
            [`& .${gridClasses.row}.even`]: {
              backgroundColor: "#efd1d1",
            },
          }}
        />
      </div>
      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to Fix All?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Fix All")}
        openDialog={confirmFixAll}
        handleDialogClose={() => setConfirmFixAll(false)}
        handleDialogAction={handleGetAndFixAll}
        // inputName= {"remark"}
        // handleInputText = {(e)=>console.log(e.target.value, 3184)}
      />
    </div>
  );
};

// export default AvailabilityOta;

const mapStateToProps = (state) => {
  return {
    roomCategoryTypes: state.roomRate.roomCategoryTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    roomCategoryTypesSuccess: (data) =>
      dispatch(roomCategoryTypesSuccess(data)),
    roomCategoryTypesFailed: () => dispatch(roomCategoryTypesFailed()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityOta);
