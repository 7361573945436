import FullCalendar from "@fullcalendar/react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import multiMonthPlugin from "@fullcalendar/multimonth";
import dayGridPlugin from "@fullcalendar/daygrid";
import { connect } from "react-redux";
import {
  propertyIdFailed,
  propertyIdSuccess,
  roomCategoryTypesFailed,
  roomCategoryTypesSuccess,
} from "../../Reducer/roomRateSlice";
import { createRecord, fetchList } from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../layout/Loader";
import CreateRatesAndAvailability from "../Content/CreateRatesAndAvailability";
import DialogForm from "../../shared/components/DialogForm";
import RateAccordingToOtas from "../Content/RatesAccordingOtas";
import AvailabilityAccordingToOtas from "../Content/AvailibilityAccordingOtas";
import QueueIcon from "@mui/icons-material/Queue";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import {
  createAvailibilityAccordingOTA,
  createRateAccordingOTA,
  createRatesAndAvailability,
  removeAvailibilityAccordingOTA,
  removeRateAccordingOTA,
} from "../../apis/services/ChannelManagerApiService";
import SyncIcon from "@mui/icons-material/Sync";
import { ErrorMessage, Field, Formik } from "formik";
import {
  ImportMinStayValidator,
  ImportOtaBookingValidator,
} from "../../shared/validations/AdminValidator";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import ShowInputError from "../../shared/components/ShowInputError";
import RemoveRatesAndAvailability from "../Content/RemoveRatesAndAvailability";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getLoadingFailed, getLoadingSuccess } from "../../Reducer/authSlice";
import { otaOverviewCalenderChange } from "../../Reducer/FilterSlice";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import allLocales from "@fullcalendar/core/locales-all";
const ExelJS = require("exceljs");

const OtaOverview = (props) => {
  const {
    roomCategoryTypes,
    roomCategoryTypesSuccess,
    roomCategoryTypesFailed,
    propertyIdSuccess,
    propertyIdFailed,
    hotelID,
    getLoadingSuccess,
    getLoadingFailed,
    calenderViewChange,
    calenderView,
    ota,
    otaCodeList,
  } = props;

  const { t } = useTranslation();
  // const [initialDate, setInitialDate] = useState(moment().format("YYYY-MM-DD"));
  const calendarRef = useRef(null);
  // const [perMonth, setPerMonth] = useState(7);
  const [arg, setArg] = useState({});
  const [eventss, setreservationEventss] = useState([]);
  // const [otaCodeList, setOtaCodeList] = useState([]);
  const [roomRateIdData, setRoomRateIdData] = useState([]);
  // const [ota, setOta] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedEventStart, setEditedEventStart] = useState("");
  const [editedEvent, setEditedEvent] = useState(null);
  const [openDayInventoryChange, setOpenDayInventoryChange] = useState(false);
  const [openDayRateChange, setOpenDayRateChange] = useState(false);
  // const [hotelID, setHotelID] = useState("");
  // const [adultRate, setAdultRate] = useState(0);
  // const [childRate, setChildRate] = useState(0);
  const [openWebRezPro, setOpenWebRezPro] = useState(false);
  const [editedEventTitle, setEditedEventTitle] = useState("");
  const [removeRates, setRemoveRates] = useState(false);
  const [resources, setResourcess] = useState([]);
  const [
    openChangeRatesAndAvailabilityDialog,
    setOpenChangeRatesAndAvailabilityDialog,
  ] = useState(false);
  const [openMinStayUpdateDialog, setOpenMinStayUpdateDialog] = useState(false);

  const [
    openChangeRatesAccordingOtaDialog,
    setOpenChangeRatesAccordingOtaDialog,
  ] = useState(false);
  const [
    openChangeAvailabilityAccorOtaDialog,
    setOpenChangeAvailabilityAccorOtaDialog,
  ] = useState(false);
  const [
    openRemoveRatesAndAvailabilityDialog,
    setOpenRemoveRatesAndAvailabilityDialog,
  ] = useState(false);

  const handleRatesAndAvailabilityDialog = () => {
    setOpenChangeRatesAndAvailabilityDialog(false);
    handleGetAllBookingRate(arg);
  };

  const handleRatesAccordingOtaDialog = () => {
    setOpenChangeRatesAccordingOtaDialog(false);
    handleGetAllBookingRate(arg);
  };

  const handleAvailabilityAccorOtaDialog = () => {
    setOpenChangeAvailabilityAccorOtaDialog(false);
    handleGetAllBookingRate(arg);
  };

  const handleRemoveRatesAndAvailabilityDialog = () => {
    setOpenRemoveRatesAndAvailabilityDialog(false);
    handleGetAllBookingRate(arg);
  };

  // console.log(eventss, 96)

  const initialState_WebRezPro = {
    id_proof: null,
    rateid: "",
  };

  const initialState_minStay = {
    id_proof: null,
    rateid: "",
  };

  const renderError = (message) => (
    <p style={{ color: "red" }} className="help is-danger">
      {message}
    </p>
  );

  const handleMinStaySubmit = async (values, resetForm) => {
    getLoadingSuccess();
    const formData = new FormData();
    if (values.id_proof?.type === "text/csv") {
      formData.append("file", values.id_proof);
      // formData.append("ota", values.ota_code);
      formData.append("rate_plan_id", values.rateid);

      const result = await createRecord(formData, ApiEndPoints.UPLOAD_MIN_STAY);

      if (result?.status === 200) {
        // setLoading(true);
        toast.success(t(result.message));
        setOpenMinStayUpdateDialog(false);
        getLoadingFailed();
        handleGetAllBookingRate(arg);
        resetForm();
        calendarRef.current.getApi().removeAllEvents();
        calendarRef.current.getApi().addEventSource(eventss);

        // Refetch and render the updated events
        calendarRef.current.getApi().refetchEvents();
      } else {
        getLoadingFailed();
        toast.error(t(result.message));
      }
    } else {
      toast.error(t("Please Upload CSV File Only"));
      getLoadingFailed();
      resetForm();
    }
  };

  const handleSubmit = async (values, resetForm) => {
    getLoadingSuccess();
    const formData = new FormData();
    if (values.id_proof?.type === "text/csv") {
      formData.append("file", values.id_proof);
      // formData.append("ota", values.ota_code);
      formData.append("rate_plan_id", values.rateid);

      const result = await createRecord(
        formData,
        ApiEndPoints.UPLOAD_CENTRAL_RATES
      );

      if (result?.status === 200) {
        // setLoading(true);
        toast.success(t(result.message));
        setOpenWebRezPro(false);
        getLoadingFailed();
        handleGetAllBookingRate(arg);
        resetForm();
        calendarRef.current.getApi().removeAllEvents();
        calendarRef.current.getApi().addEventSource(eventss);

        // Refetch and render the updated events
        calendarRef.current.getApi().refetchEvents();
      } else {
        getLoadingFailed();
        toast.error(t(result.message));
      }
    } else {
      toast.error(t("Please Upload CSV File Only"));
      getLoadingFailed();
      resetForm();
    }
  };

  const handleEventClick = async (info) => {
    if (
      info?.event?.title === "" ||
      info?.event?.extendedProps?.eventType === "availability" ||
      info?.event?.extendedProps?.eventType === "MinStay"

      // ||
      // (info?.event?.extendedProps?.type === 1 &&
      //   info?.event?.extendedProps?.eventType === "inventory")
    ) {
      return;
    }
    // console.log(info.event, 103);
    setEditedEvent(info.event);
    setEditedEventStart(moment(info.event.start).format("YYYY-MM-DD"));
    if ((info?.event?.title).includes("/")) {
      setEditedEventTitle(info?.event?.extendedProps?.titleOri);
    } else {
      setEditedEventTitle(info.event.title);
    }
    setRemoveRates(false);
    if (
      info?.event?.extendedProps?.eventType === "rate" ||
      info?.event?.extendedProps?.eventType === "suggestedRate"
    ) {
      // setAdultRate(info?.event?.extendedProps?.extra_adult_rate);
      // setChildRate(info?.event?.extendedProps?.extra_child_rate);
      setOpenDayRateChange(true);
    } else {
      setOpenDayInventoryChange(true);
    }
  };

  const handleSaveEvent = async () => {
    if (editedEvent) {
      getLoadingSuccess();
      if (editedEvent?.extendedProps?.type !== 1) {
        let otaFilCodeList = otaCodeList.map((item) => item?.value);
        // .filter(ele => ele !== editedEvent?.extendedProps?.ota_code);
        const otaRuleData = await fetchList(
          ApiEndPoints.GET_OTA_RATES_OVERVIEW +
            `?start_date=${moment(editedEventStart).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(editedEventStart).format(
              "YYYY-MM-DD"
            )}&ota_code=${otaFilCodeList}`
        );
        if (otaRuleData?.status === 200) {
          let rateControl = otaRuleData?.data
            ?.filter(
              (item) =>
                item?.rate_rule_type !== 0 &&
                roomCategoryTypes?.filter(
                  (el) => el?.id === +item?.category_id
                )[0]?.type === editedEvent?.extendedProps?.categoryId &&
                item?.ota_code !== +editedEvent?.extendedProps?.ota_code
            )
            ?.map((item) => ({
              roomid: roomCategoryTypes?.filter(
                (el) => el?.id === item?.category_id
              )[0]?.type,
              rateid: item?.rate_plan_id,
              date: [
                {
                  from: editedEventStart,
                  to: editedEventStart,
                  OTARule: [
                    {
                      OTACode: [item?.ota_code],
                      rule: {
                        type:
                          item?.rate_rule_type === 1
                            ? `Fixed`
                            : item?.rate_rule_type === 2
                            ? `Increase`
                            : item?.rate_rule_type === 3
                            ? `Decrease`
                            : `Multiply`,
                        value: item?.rate_rule_value,
                      },
                    },
                  ],
                },
              ],
            }));

          if (removeRates && rateControl?.length === 0) {
            rateControl?.push({
              roomid: editedEvent?.extendedProps?.categoryId,
              rateid: editedEvent?.extendedProps?.rate_plan_id,
              date: [
                {
                  from: editedEventStart,
                  to: editedEventStart,
                  OTARule: [],
                },
              ],
            });
          } else if (!removeRates) {
            rateControl?.push({
              roomid: editedEvent?.extendedProps?.categoryId,
              rateid: editedEvent?.extendedProps?.rate_plan_id,
              date: [
                {
                  from: editedEventStart,
                  to: editedEventStart,
                  OTARule: [
                    {
                      OTACode: [editedEvent?.extendedProps?.ota_code],
                      rule: {
                        type: "Fixed",
                        value: editedEventTitle,
                      },
                    },
                  ],
                },
              ],
            });
          }

          // console.log(rateControl, 202);

          let payload = {
            hotelid: hotelID,
            ratecontrol: rateControl,
          };

          let payloadRemove = {
            hotelid: hotelID,
            ratecontrol: rateControl,
          };

          const result = await createRateAccordingOTA(payload);
          if (result?.status === 200) {
            setOpenDayRateChange(false);
            toast.success(t(result.message));
            setEditedEventTitle("");
            // setAdultRate("");
            // setChildRate("");
            setRemoveRates(false);
            handleGetAllBookingRate(arg);
            setEditedEvent(null);
            getLoadingFailed();
          } else {
            toast.error(t(result.message));
            getLoadingFailed();
          }
        } else {
          toast.error(t(otaRuleData?.message));
          getLoadingFailed();
        }
      } else {
        // console.log("Central rate", editedEvent?.extendedProps?.categoryId);

        let roomInfoPayload = [
          {
            roomid: editedEvent?.extendedProps?.categoryId,
            rate: [
              {
                rateplanid: editedEvent?.extendedProps?.rate_plan_id,
              },
            ],
            price: [
              {
                NumberOfGuests: roomCategoryTypes?.filter(
                  (el) => el?.type === editedEvent?.extendedProps?.categoryId
                )[0]?.max_occupancy,
                value: editedEventTitle,
              },
            ],
            closed: 0,
            minimumstay: 0,
            maximumstay: 0,
            closedonarrival: 0,
            extraadultrate: editedEvent?.extendedProps?.extra_adult_rate,
            extrachildrate: editedEvent?.extendedProps?.extra_child_rate,
            closedondeparture: 0,
            showDate: 0,
            sendAllData: 3,
            value: editedEventStart,
            // "to": "2024-02-29"
          },
        ];
        const payload = {
          hotelid: hotelID,
          rooms: roomInfoPayload,
        };

        const result = await createRatesAndAvailability(payload);

        if (result?.status === 200) {
          setOpenDayRateChange(false);
          toast.success(t(result.message));
          setEditedEventTitle("");
          // setAdultRate("");
          // setChildRate("");
          setRemoveRates(false);
          handleGetAllBookingRate(arg);
          setEditedEvent(null);
          getLoadingFailed();
        } else {
          toast.error(t(result.message));
          getLoadingFailed();
        }
      }
    }
  };

  const handleSaveInventoryEvent = async () => {
    if (editedEvent) {
      getLoadingSuccess();
      if (editedEvent?.extendedProps?.type !== 1) {
        // console.log(editedEvent, eventss, 231);
        let centralRateArray = eventss?.filter(
          (item) =>
            item?.eventType === "inventory" &&
            item?.ota_code === 0 &&
            item?.categoryId === editedEvent?.extendedProps?.categoryId &&
            item?.start === editedEventStart
        );
        let centralRate =
          centralRateArray?.length > 0 ? centralRateArray[0] : 0;
        if (+editedEventTitle > centralRate?.title && !removeRates) {
          toast.error(t("Entered Inventory Is Greater Than Central Inventory"));
          getLoadingFailed();
          return;
        }

        let otaFilCodeList = otaCodeList.map((item) => item?.value);
        // .filter(ele => ele !== editedEvent?.extendedProps?.ota_code);
        const otaRuleData = await fetchList(
          ApiEndPoints.GET_OTA_RATES_OVERVIEW +
            `?start_date=${moment(editedEventStart).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(editedEventStart).format(
              "YYYY-MM-DD"
            )}&ota_code=${otaFilCodeList}`
        );
        if (otaRuleData?.status === 200) {
          // console.log(otaRuleData?.data?.filter((item)=>(item?.inventory_rule_type !== 0 &&
          //   (roomCategoryTypes?.filter((el) => (el?.id === +item?.category_id))[0]?.type === editedEvent?.extendedProps?.categoryId)
          //   && (item?.ota_code !== +editedEvent?.extendedProps?.ota_code))),
          //   otaRuleData?.data?.filter((item)=>(roomCategoryTypes?.filter((el) => (el?.id === +item?.category_id))[0]?.type === editedEvent?.extendedProps?.categoryId))
          //   ,328)
          let inventoryControl = otaRuleData?.data
            ?.filter(
              (item) =>
                item?.inventory_rule_type !== 0 &&
                roomCategoryTypes?.filter(
                  (el) => el?.id === +item?.category_id
                )[0]?.type === editedEvent?.extendedProps?.categoryId &&
                item?.ota_code !== +editedEvent?.extendedProps?.ota_code
            )
            ?.map((item) => ({
              roomid: roomCategoryTypes?.filter(
                (el) => el?.id === item?.category_id
              )[0]?.type,
              date: [
                {
                  from: editedEventStart,
                  to: editedEventStart,
                  OTARule: [
                    {
                      OTACode: [item?.ota_code],
                      rule: {
                        type:
                          item?.inventory_rule_type === 1
                            ? `Fixed`
                            : item?.inventory_rule_type === 2
                            ? `Increase`
                            : item?.inventory_rule_type === 3
                            ? `Decrease`
                            : `Multiply`,
                        value: item?.inventory_rule_value,
                      },
                    },
                  ],
                },
              ],
            }));

          if (removeRates && inventoryControl?.length === 0) {
            inventoryControl?.push({
              roomid: editedEvent?.extendedProps?.categoryId,
              date: [
                {
                  from: editedEventStart,
                  to: editedEventStart,
                  OTARule: [],
                },
              ],
            });
          } else if (!removeRates) {
            // console.log(
            //   centralRate?.title - +editedEventTitle,
            //   centralRate?.title,
            //   editedEventTitle
            // );
            inventoryControl?.push({
              roomid: editedEvent?.extendedProps?.categoryId,
              date: [
                {
                  from: editedEventStart,
                  to: editedEventStart,
                  OTARule: [
                    {
                      OTACode: [editedEvent?.extendedProps?.ota_code],
                      rule: {
                        type: "Decrease",
                        value: +centralRate?.titleOri - +editedEventTitle,
                      },
                    },
                  ],
                },
              ],
            });
          }

          let payload = {
            hotelid: hotelID,
            inventorycontrol: inventoryControl,
          };

          let payloadRemove = {
            hotelid: hotelID,
            inventorycontrol: inventoryControl,
          };

          // getLoadingFailed();
          const result = await createAvailibilityAccordingOTA(payload);
          if (result?.status === 200) {
            setOpenDayInventoryChange(false);
            toast.success(t(result.message));
            setEditedEventTitle("");
            handleGetAllBookingRate(arg);
            setEditedEvent(null);
            setRemoveRates(false);
            getLoadingFailed();
          } else {
            toast.error(
              result?.message?.Errors?.length > 0
                ? t(result?.message?.Errors[0]?.ShortText)
                : t(result?.message?.Errors?.ShortText)
            );
            getLoadingFailed();
          }
        } else {
          toast.error(t(otaRuleData?.message));
          getLoadingFailed();
        }
      } else {
        if (+editedEventTitle > +editedEvent?.extendedProps?.titleMax) {
          toast?.error(t("Entered Number is greater than Availabile Rooms"));
          getLoadingFailed();
          return;
        }
        let payloadr = {
          hotelid: hotelID,
          rooms: [
            {
              roomid: editedEvent?.extendedProps?.categoryId,
              rate: [
                {
                  rateplanid: editedEvent?.extendedProps?.rate_plan_id,
                },
              ],
              price: [
                {
                  NumberOfGuests: "",
                  value: "",
                },
              ],
              closed: 0,
              minimumstay: 0,
              maximumstay: 0,
              closedonarrival: 0,
              extraadultrate: 0,
              extrachildrate: 0,
              closedondeparture: 0,
              showDate: 0,
              sendAllData: 2,
              value: editedEventStart,
              // to: "2024-02-29",
              roomstosell: editedEventTitle,
            },
          ],
        };
        const result = await createRatesAndAvailability(payloadr);

        if (result?.status === 200) {
          setOpenDayInventoryChange(false);
          toast.success(t(result.message));
          setEditedEventTitle("");
          handleGetAllBookingRate(arg);
          setEditedEvent(null);
          setRemoveRates(false);
          getLoadingFailed();
        } else {
          toast.error(t(result.message));
          getLoadingFailed();
        }
      }
    }
  };

  const handleCancelEdit = () => {
    setOpenDayRateChange(false);
    setEditedEventTitle("");
    // setAdultRate("");
    // setChildRate("");
    setRemoveRates(false);
  };

  const handleCancelInventoryEdit = () => {
    setOpenDayInventoryChange(false);
    setOpenDayRateChange(false);
    setEditedEventTitle("");
    // setAdultRate("");
    // setChildRate("");
    setRemoveRates(false);
  };

  const getRoomData = async () => {
    // setLoading(true)
    const resultRe = await fetchList(ApiEndPoints.ROOM_CATEGORY_SELECT_LIST);
    const RoomData = [];
    if (resultRe?.status === 200) {
      for (var j in resultRe.data) {
        RoomData.push({
          id: resultRe.data[j].id,
          // title: resultRe.data[j].room_name,
          type: resultRe.data[j]?.room_type_short_code,
          type_id: resultRe.data[j].id,
          max_occupancy: resultRe.data[j]?.max_occupancy,
          // housekeeping: resultRe.data[j]?.houseKeepingStatus?.status,
        });
      }
      if (!(JSON.stringify(RoomData) === JSON.stringify(roomCategoryTypes))) {
        roomCategoryTypesSuccess(RoomData);
      }
    } else {
      roomCategoryTypesFailed();
    }
  };

  // console.log(roomCategoryTypes, 160)

  useEffect(() => {
    if (!hotelID) {
      getPropertyId();
    }
    getOtaCodeList();
    getRoomData();
  }, []);

  useEffect(() => {
    if (hotelID && roomRateIdData?.length === 0) {
      getRoomRateData();
    }
  }, [hotelID]);

  const columns = [
    {
      field: "room",
      group: true,
      headerContent: t("Room Type"),
      cellContent: (args) =>
        t(args?.groupValue) || t(args?.resource?.extendedProps?.room),
    },
    {
      field: "ota",
      group: true,
      headerContent: t("OTA Type"),
      cellContent: (args) =>
        t(args?.groupValue) || t(args?.resource?.extendedProps?.ota),
    },

    {
      field: "type",
      headerContent: t("Type"),
      cellContent: (args) => t(args?.resource?.extendedProps?.type),
    },
  ];

  // console.log(otaCodeList, 240);

  const exportToExcel = (dataHead, data) => {
    // console.log(dataHead,data, 634 )
    const workbook = new ExelJS.Workbook();
    const sheet = workbook.addWorksheet("rate ");
    sheet.columns = dataHead?.map((item) => ({
      key: item,
      width: 20,
    }));

    data?.map((columnData, index) => {
      const dataColumn = sheet?.addRow(columnData);
    });
    const hotelDetail = JSON.parse(localStorage.getItem("userDetails"));

    workbook.csv.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "text/csv;charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      // document.body.appendChild(a);
      a.href = url;
      a.download =
        hotelDetail?.hotelDetail?.company_name +
        "_" +
        moment().format("MM-DD-YYYY");
      a.click();
      window.URL.revokeObjectURL(url);
      // document.body.removeChild(a);
    });
  };

  const exportRates = async () => {
    let dateHeader = ["cate"];
    let dateArray = [];

    const result = await fetchList(
      ApiEndPoints.GET_OTA_RATES_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&ota_code=${[0]}`
    );
    let objHead = {};
    if (result?.status === 200) {
      result?.data?.forEach((item) => {
        if (!dateHeader?.includes(item?.date)) {
          dateHeader?.push(item?.date);
          objHead["cate"] = "";
          objHead[item?.date] = moment(item?.date, "YYYY-MM-DD")?.format(
            "DD-MMM-YY"
          );
        }
      });
      dateArray?.push(objHead);

      roomCategoryTypes?.forEach((items) => {
        let obj = {};

        result?.data
          ?.filter((item) => item?.category_id === items?.id)
          ?.forEach((item) => {
            obj["cate"] = items?.type;
            obj[item?.date] = "$" + item?.default_occupancy_rate;
          });
        dateArray?.push(obj);
      });
    }

    exportToExcel(dateHeader, dateArray);
  };

  const [bookingData, setBookingData] = useState({
    suggestedResultData: [],
    availabilityData: [],
    otaAvailInv: {},
    result: {},
  });

  const handleGetOverview = async (arg) => {
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.GET_OTA_RATES_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&ota_code=${[
          ota,
        ]}`
    );

    setBookingData((prev) => ({
      ...prev,
      result: result,
    }));
    // setLoading(false);
  };
  const handleGetSuggestedResult = async (arg) => {
    setLoading(true);
    const suggestedResult = await fetchList(
      ApiEndPoints.GET_SUGGESTED_RATE_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&ota_code=${[
          ota,
        ]}`
    );
    if (suggestedResult?.status === 200) {
      setBookingData((prev) => ({
        ...prev,
        suggestedResultData: suggestedResult?.data,
      }));
      // setLoading(false);
    } else {
      setBookingData((prev) => ({
        ...prev,
        suggestedResultData: [],
      }));
      // setLoading(false);
    }
  };

  const handleGetResultAvail = async (arg) => {
    setLoading(true);

    const resultAvail = await fetchList(
      ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
        `?start_date=${moment(arg?.startStr).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}`
    );
    if (resultAvail?.status === 200) {
      setBookingData((prev) => ({
        ...prev,
        availabilityData: resultAvail?.data,
      }));
      // setLoading(false);
    } else {
      setBookingData((prev) => ({
        ...prev,
        availabilityData: [],
      }));
      // setLoading(false);
    }
  };

  const handleGetOtaAvail = async (arg) => {
    setLoading(true);

    const dateIsBefore = moment(arg?.startStr).isBefore(moment());

    const otaAvail = await createRecord(
      {
        hotelId: hotelID,
        startDate: dateIsBefore
          ? moment().format("YYYY-MM-DD")
          : moment(arg?.startStr).format("YYYY-MM-DD"),
        endDate: moment(arg?.endStr).format("YYYY-MM-DD"),
      },
      ApiEndPoints.OTA_AVAILABILITY_INVENTORY
    );
    if (otaAvail?.status === 200) {
      setBookingData((prev) => ({
        ...prev,
        otaAvailInv: otaAvail?.data,
      }));
      // setLoading(false);
      //
    } else {
      setBookingData((prev) => ({
        ...prev,
        otaAvailInv: {},
      }));
      // setLoading(false);
    }
  };

  const handleGetAllBookingRate = (arg) => {
    handleGetSuggestedResult(arg);
    handleGetResultAvail(arg);
    handleGetOtaAvail(arg);
    handleGetOverview(arg);
  };

  const handleGetAllBookingRateData = async (arg) => {
    // setLoading(true);

    let dateArray = [];
    // const result = await fetchList(
    //   ApiEndPoints.GET_OTA_RATES_OVERVIEW +
    //     `?start_date=${moment(arg?.startStr).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&ota_code=${[
    //       ota,
    //     ]}`
    // );
    // const suggestedResult = await fetchList(
    //   ApiEndPoints.GET_SUGGESTED_RATE_OVERVIEW +
    //     `?start_date=${moment(arg?.startStr).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}&ota_code=${[
    //       ota,
    //     ]}`
    // );
    // const resultAvail = await fetchList(
    //   ApiEndPoints.GET_CALENDER_AVAILABILITY_OVERVIEW +
    //     `?start_date=${moment(arg?.startStr).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(arg?.endStr).format("YYYY-MM-DD")}`
    // );

    // const dateIsBefore = moment(arg?.startStr).isBefore(moment());

    // const otaAvail = await createRecord(
    //   {
    //     hotelId: hotelID,
    //     startDate: dateIsBefore
    //       ? moment().format("YYYY-MM-DD")
    //       : moment(arg?.startStr).format("YYYY-MM-DD"),
    //     endDate: moment(arg?.endStr).format("YYYY-MM-DD"),
    //   },
    //   ApiEndPoints.OTA_AVAILABILITY_INVENTORY
    // );
    let result = bookingData?.result;
    let otaAvailInv = {};
    otaAvailInv = bookingData?.otaAvailInv;
    // if (otaAvail?.status === 200) {
    //   otaAvailInv = otaAvail?.data;
    // }

    let suggestedResultData = [];
    suggestedResultData = bookingData?.suggestedResultData;

    // if (suggestedResult?.status === 200) {
    //   suggestedResultData = suggestedResult?.data;
    // }

    let availabilityData = [];
    availabilityData = bookingData?.availabilityData;

    // if (resultAvail?.status === 200) {
    //   availabilityData = resultAvail?.data;
    // }

    if (result?.status === 200) {
      result?.data?.map((item) => {
        ota?.forEach((otaItem, index) => {
          let sugeData = [];
          let roomCateCo = roomCategoryTypes?.filter(
            (sitem) => sitem?.id === item?.category_id
          );
          if (otaItem === item?.ota_code && roomCateCo?.length > 0) {
            dateArray.push({
              resourceId: item?.category_id + "A" + roomCateCo[0]?.type + index,
              start: moment(item?.date).format("YYYY-MM-DD"),
              title: item?.default_occupancy_rate,
              textColor: "black",
              sessionId: item?.id,
              color: "transparent",
              eventType: "rate",
              extra_adult_rate: item?.extra_adult_rate,
              extra_child_rate: item?.extra_child_rate,
              categoryId: roomCateCo[0]?.type,
              ota_code: item?.ota_code,
              rate_plan_id: item?.rate_plan_id,
              rate_rule_type: item?.rate_rule_type,
              rate_rule_value: item?.rate_rule_value,
              type: item?.type,
            });
            // console.log(otaItem,503)
            sugeData = suggestedResultData?.filter(
              (suItem) =>
                suItem?.date === moment(item?.date).format("YYYY-MM-DD") &&
                suItem?.ota_code === otaItem &&
                suItem?.category_id === item?.category_id
            );
            dateArray.push({
              resourceId:
                item?.category_id + "B" + 1000 + roomCateCo[0]?.type + index,
              category_id: item?.category_id,
              start: moment(item?.date).format("YYYY-MM-DD"),
              title: sugeData?.length > 0 ? sugeData[0]?.rate : "-",
              textColor: "black",
              sessionId: item?.id,
              sugId: sugeData?.length > 0 ? sugeData[0]?.id : "",
              color: "transparent",
              eventType: "suggestedRate",
              ota: otaCodeList?.filter((el) => otaItem === el?.value)[0]?.name,
              type: item?.type,
              ota_code: otaItem,
              categoryId: roomCategoryTypes?.filter(
                (el) => el?.id === item?.category_id
              )[0]?.type,
              rate_plan_id: item?.rate_plan_id,
              // className: "color-blue"
            });
            // }

            dateArray.push({
              resourceId:
                item?.category_id + "C" + 1000000 + roomCateCo[0]?.type + index,
              start: moment(item?.date).format("YYYY-MM-DD"),
              title: item?.availability,
              textColor: "black",
              sessionId: item?.id,
              color: "transparent",
              eventType: "availability",
              ota: otaCodeList?.filter(
                (el) => otaItem?.otaItem === el?.value
              )[0]?.name,
            });

            let availData = availabilityData?.filter(
              (aItem) =>
                +aItem?.catId === +item?.category_id &&
                +item?.ota_code === 0 &&
                aItem?.date === item?.date
            );
            // console.log(availData, 551);
            dateArray.push({
              resourceId:
                item?.category_id +
                "D" +
                1000000000 +
                roomCateCo[0]?.type +
                index,
              start: moment(item?.date).format("YYYY-MM-DD"),
              title:
                availData?.length > 0
                  ? item?.availability + "/" + availData[0]?.availableRooms
                  : item?.availability,
              titleOri: item?.availability,
              titleMax: availData[0]?.availableRooms,
              textColor: "black",
              sessionId: item?.id,
              color: "transparent",
              eventType: "inventory",
              availability:
                availData?.length > 0
                  ? +item?.availability > +availData[0]?.availableRooms
                  : false,
              ota: otaCodeList?.filter(
                (el) => otaItem?.otaItem === el?.value
              )[0]?.name,
              categoryId: roomCategoryTypes?.filter(
                (el) => el?.id === item?.category_id
              )[0]?.type,
              ota_code: item?.ota_code,
              inventory_rule_type: item?.inventory_rule_type,
              inventory_rule_value: item?.inventory_rule_value,
              type: item?.type,
            });

            if (
              // otaItem === 0 &&
              otaAvailInv &&
              Object.keys(otaAvailInv)?.length > 0 &&
              otaAvailInv[moment(item?.date).format("YYYY-MM-DD")] &&
              otaAvailInv[moment(item?.date).format("YYYY-MM-DD")][
                roomCateCo[0]?.type
              ]?.rateplan &&
              Object.keys(
                otaAvailInv[moment(item?.date).format("YYYY-MM-DD")][
                  roomCateCo[0]?.type
                ]["rateplan"]
              )?.length > 0
            ) {
              // console.log(
              //   otaAvailInv[moment(item?.date).format("YYYY-MM-DD")][
              //     roomCateCo[0]?.type
              //   ]["inventory"],
              //   814
              // );
              dateArray.push({
                resourceId:
                  item?.category_id +
                  "E" +
                  1000000000000 +
                  roomCateCo[0]?.type +
                  index,
                start: moment(item?.date).format("YYYY-MM-DD"),
                title:
                  otaAvailInv[moment(item?.date).format("YYYY-MM-DD")][
                    roomCateCo[0]?.type
                  ]["rateplan"][
                    Object.keys(
                      otaAvailInv[moment(item?.date).format("YYYY-MM-DD")][
                        roomCateCo[0]?.type
                      ]["rateplan"]
                    )[0]
                  ]?.minimumstay,
                textColor: "black",
                sessionId: item?.id,
                color: "transparent",
                eventType: "MinStay",
                availability:
                  availData?.length > 0
                    ? +item?.availability !==
                      +otaAvailInv[moment(item?.date).format("YYYY-MM-DD")][
                        roomCateCo[0]?.type
                      ]["inventory"]
                    : false,
                ota: otaCodeList?.filter(
                  (el) => otaItem?.otaItem === el?.value
                )[0]?.name,
                categoryId: roomCategoryTypes?.filter(
                  (el) => el?.id === item?.category_id
                )[0]?.type,
                ota_code: item?.ota_code,
                inventory_rule_type: item?.inventory_rule_type,
                inventory_rule_value: item?.inventory_rule_value,
                type: item?.type,
              });
            }
          }
        });
      });

      // if(suggestedResult?.status === 200){
      suggestedResultData?.map((item) => {
        ota?.forEach((otaItem, index) => {
          let roomCateCo = roomCategoryTypes?.filter(
            (sitem) => sitem?.id === item?.category_id
          );
          if (otaItem === item?.ota_code && roomCateCo?.length > 0) {
            let filterDateArray = dateArray?.filter(
              (fitem) =>
                fitem?.resourceId ===
                item?.category_id + "B" + 1000 + roomCateCo[0]?.type + index
            );
            // console.log(filterDateArray, 558);
            if (filterDateArray?.length === 0) {
              dateArray.push({
                resourceId:
                  item?.category_id + "B" + 1000 + roomCateCo[0]?.type + index,
                start: moment(item?.date).format("YYYY-MM-DD"),
                title: item?.rate,
                textColor: "black",
                sessionId: item?.id,
                ota_code: otaItem,
                color: "transparent",
                eventType: "suggestedRate",
                ota: otaCodeList?.filter((el) => otaItem === el?.value)[0]
                  ?.name,
                type: item?.type,
                // className: "color-blue"
              });
            }
          }
        });
      });
      // }
      // console.log(dateArray.filter((item) => item?.title !== ""), 586)
      setreservationEventss(dateArray.filter((item) => item?.title !== ""));
      setLoading(false);
    } else {
      setreservationEventss([]);

      setLoading(false);
    }
  };

  const getOtaCodeList = async () => {
    if (otaCodeList?.length === 0) {
      const result = await fetchList(ApiEndPoints.OTA_LIST_FOR_SELECT);
      if (result?.status === 200) {
        let arry = result?.data?.map((item) => ({
          name: item?.ota_name,
          value: item?.ota_code,
        }));
        arry.push({
          name: "Central",
          value: 0,
        });
        // console.log(calenderView, arry)
        // calenderViewChange({...calenderView, });
        calenderViewChange({
          ...calenderView,
          otaCodeList: arry,
          ota: arry?.length > 0 ? [0] : [],
        });
      } else {
        console.log(result.message + ", please check!");
        calenderViewChange({ ...calenderView, otaCodeList: [] });
      }
    }
  };
  // console.log(calenderView, 189)

  const [ignoreTest, setIgnoreTest] = useState(false);

  useEffect(() => {
    if (
      // roomCategoryTypes?.length > 0 &&
      arg &&
      ota?.length > 0 &&
      hotelID &&
      arg?.startStr !== arg?.endStr
    ) {
      handleGetAllBookingRate(arg);
      // console.log(arg, 1101);
      // setIgnoreTest(false);
    }
    // return () => {
    //   setIgnoreTest(true);
    // };
  }, [ota, arg, hotelID]);

  useEffect(() => {
    if (roomCategoryTypes?.length > 0) {
      handleGetAllBookingRateData(arg);
    }
  }, [bookingData, roomCategoryTypes]);

  // useEffect(() => {
  //   setIgnoreTest(true);
  // }, [ignoreTest]);

  console.log(bookingData, 1241);

  const getPropertyId = async () => {
    const userHotelID = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))["hotel_id"]
      : 0;
    const result = await fetchList(
      ApiEndPoints.GET_HOTEL_DETAILS + userHotelID
    );

    if (result?.status === 200) {
      propertyIdSuccess(result?.data?.property_id);
      // setHotelID(result?.data?.property_id);
    } else {
      propertyIdFailed();
      console.log(result.message.response + ", please check!");
    }
  };

  const getRoomRateData = async () => {
    const listing_rate_result = await createRecord(
      {
        hotelid: hotelID,
      },
      ApiEndPoints.GET_RATE_PLAN
    );
    // const RatePlanList = [];

    if (listing_rate_result?.status === 200) {
      const orgResult = listing_rate_result.data.rateplans;
      const RatePlanObj = [];

      for (var i in orgResult) {
        RatePlanObj.push({
          value: orgResult[i].rateplanid,
          name: orgResult[i].rateplanname,
          // status: orgResult[j].status,
          // mealplanid: orgResult[j].mealplanid,
        });
      }

      setRoomRateIdData(RatePlanObj);
    } else {
      console.log(listing_rate_result.message.response + ", please check!");
    }
  };

  let ignore;
  // useEffect(() => {
  //   if (!ignore) {

  //   }
  //   return () => {
  //     ignore = true;
  //   };
  // }, []);

  useEffect(() => {
    if (ota?.length > 0) {
      // console.log(ota,roomCategoryTypes, 205)
      let array = [];
      for (let i in roomCategoryTypes) {
        ota?.forEach((item, index) => {
          array.push({
            id:
              roomCategoryTypes[i]?.id +
              "A" +
              roomCategoryTypes[i]?.type +
              index,
            room: roomCategoryTypes[i]?.type,
            type: "Rate",
            ota: otaCodeList?.filter((el) => item === el?.value)[0]?.name,
          });
          array.push({
            id:
              roomCategoryTypes[i]?.id +
              "B" +
              1000 +
              roomCategoryTypes[i]?.type +
              index,
            room: roomCategoryTypes[i]?.type,
            type: "Suggested Rate",
            ota: otaCodeList?.filter((el) => item === el?.value)[0]?.name,
          });
          array.push({
            id:
              roomCategoryTypes[i]?.id +
              "C" +
              1000000 +
              roomCategoryTypes[i]?.type +
              index,
            type: "Availability",
            ota: otaCodeList?.filter((el) => item === el?.value)[0]?.name,
            // type_id: roomCategoryTypes[i]?.id*1000,
            room: roomCategoryTypes[i]?.type,
          });
          array.push({
            id:
              roomCategoryTypes[i]?.id +
              "D" +
              1000000000 +
              roomCategoryTypes[i]?.type +
              index,
            type: "Inventory",
            ota: otaCodeList?.filter((el) => item === el?.value)[0]?.name,
            // type_id: roomCategoryTypes[i]?.id*1000000,
            room: roomCategoryTypes[i]?.type,
          });
          // if (item === 0) {
          array.push({
            id:
              roomCategoryTypes[i]?.id +
              "E" +
              1000000000000 +
              roomCategoryTypes[i]?.type +
              index,
            type: "Min Stay",
            ota: otaCodeList?.filter((el) => item === el?.value)[0]?.name,
            // type_id: roomCategoryTypes[i]?.id*1000000,
            room: roomCategoryTypes[i]?.type,
          });
          // }
        });
      }
      setResourcess(array);
    }
  }, [ota, roomCategoryTypes]);

  function renderEventContent(eventInfo, element) {
    return (
      <div className="cale-event">
        {(eventInfo?.event?.extendedProps?.eventType === "rate" ||
          eventInfo?.event?.extendedProps?.eventType === "inventory" ||
          eventInfo?.event?.extendedProps?.eventType === "MinStay" ||
          eventInfo?.event?.extendedProps?.eventType === "suggestedRate") && (
          <div
            className="d-flex justify-content-center w-full h-full pt-1 tooltip"
            style={{
              color:
                (eventInfo?.event?.extendedProps?.eventType === "inventory" &&
                  eventInfo?.event?.extendedProps?.inventory_rule_type !== 0) ||
                (eventInfo?.event?.extendedProps?.eventType === "rate" &&
                  eventInfo?.event?.extendedProps?.rate_rule_type !== 0)
                  ? "#4f46e5"
                  : eventInfo?.event?.extendedProps?.eventType ===
                      "inventory" &&
                    // ||
                    // eventInfo?.event?.extendedProps?.eventType ===
                    //   "MinStay")
                    eventInfo?.event?.extendedProps?.availability
                  ? "red"
                  : "black",
              cursor:
                eventInfo?.event?.extendedProps?.eventType === "MinStay"
                  ? "default"
                  : // eventInfo?.event?.extendedProps?.type === 1 &&
                    // eventInfo?.event?.extendedProps?.eventType === "inventory"
                    //   ? "default"
                    //   :
                    "pointer",
              backgroundColor:
                (eventInfo?.event?.extendedProps?.eventType === "inventory" &&
                  eventInfo?.event?.extendedProps?.inventory_rule_type !== 0) ||
                (eventInfo?.event?.extendedProps?.eventType === "rate" &&
                  eventInfo?.event?.extendedProps?.rate_rule_type !== 0)
                  ? "rgb(225 243 226)"
                  : eventInfo?.event?.extendedProps?.eventType ===
                    "suggestedRate"
                  ? "#6B7F9B"
                  : "white",
              paddingBottom:
                (eventInfo?.event?.extendedProps?.eventType === "inventory" &&
                  eventInfo?.event?.extendedProps?.inventory_rule_type !== 0) ||
                (eventInfo?.event?.extendedProps?.eventType === "rate" &&
                  eventInfo?.event?.extendedProps?.rate_rule_type !== 0) ||
                eventInfo?.event?.extendedProps?.eventType === "suggestedRate"
                  ? "6px"
                  : "0px",
            }}
          >
            {eventInfo.event.title}{" "}
            {((eventInfo?.event?.extendedProps?.eventType === "inventory" &&
              eventInfo?.event?.extendedProps?.inventory_rule_type !== 0) ||
              (eventInfo?.event?.extendedProps?.eventType === "rate" &&
                eventInfo?.event?.extendedProps?.rate_rule_type !== 0)) && (
              <span className="tooltiptext">
                {eventInfo?.event?.extendedProps?.eventType === "inventory"
                  ? (eventInfo?.event?.extendedProps?.inventory_rule_type === 1
                      ? `Fixed: `
                      : eventInfo?.event?.extendedProps?.inventory_rule_type ===
                        2
                      ? `Increase: `
                      : eventInfo?.event?.extendedProps?.inventory_rule_type ===
                        3
                      ? `Decrease: `
                      : `Multiply`) +
                    eventInfo?.event?.extendedProps?.inventory_rule_value
                  : (eventInfo?.event?.extendedProps?.rate_rule_type === 1
                      ? `Fixed: `
                      : eventInfo?.event?.extendedProps?.rate_rule_type === 2
                      ? `Increase: `
                      : eventInfo?.event?.extendedProps?.rate_rule_type === 3
                      ? `Decrease: `
                      : `Multiply`) +
                    eventInfo?.event?.extendedProps?.rate_rule_value}
              </span>
            )}
          </div>
        )}
        {/* { element.tooltip({title: eventInfo.title})  } */}
        {eventInfo?.event?.extendedProps?.eventType === "availability" && (
          <div
            className="d-flex justify-content-center w-100"
            style={{
              backgroundColor: +eventInfo.event.title > 0 ? "green" : "red",
              padding: "5px 0px",
              color: "white",
            }}
          >
            {+eventInfo.event.title > 0 && <CheckIcon />}
            {+eventInfo.event.title <= 0 && <CloseIcon />}
          </div>
        )}
      </div>
    );
  }

  const handleDeleteOta = (e, value) => {
    // setOta((oldValues) => {
    //   return oldValues.filter((item) => item !== value);
    // });
    calenderViewChange({
      ...calenderView,
      ota: calenderView?.ota.filter((item) => item !== value),
    });
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeOta = (e) => {
    // setOta(
    //   typeof e?.target?.value === "string"
    //     ? e?.target?.value.split(",")
    //     : e?.target?.value
    // );
    calenderViewChange({
      ...calenderView,
      ota:
        typeof e?.target?.value === "string"
          ? e?.target?.value.split(",")
          : e?.target?.value,
    });
    handleClose();
  };

  return (
    <>
      {loading && <Loader />}

      <div className="p-2" style={{ background: "#fff", width: "95%" }}>
        <div className="d-flex mt-1 align-items-center justify-content-end flex-wrap">
          <div
            className="d-flex align-items-center "
            style={{ flexWrap: "wrap" }}
          >
            <Button
              variant="outlined"
              // className="m-1"
              sx={{ color: "#000", margin: "5px" }}
              onClick={() => setOpenMinStayUpdateDialog(true)}
            >
              {t("Import Min Stay")}
              <DriveFolderUploadIcon
                className="ml-1"
                sx={{ fontSize: "18px" }}
              />
            </Button>
            <Button
              variant="outlined"
              // className="m-1"
              sx={{ color: "#000", margin: "5px" }}
              onClick={() => setOpenChangeRatesAndAvailabilityDialog(true)}
            >
              {t("Rates & Availability")}
              <QueueIcon className="ml-1" sx={{ fontSize: "18px" }} />
            </Button>
            <Button
              variant="outlined"
              // className="m-1"
              sx={{ color: "#000", margin: "5px", display: "none" }}
              onClick={() => setOpenChangeRatesAccordingOtaDialog(true)}
            >
              {t("Rates According To OTA")}
              <AddToQueueIcon className="ml-1" sx={{ fontSize: "18px" }} />
            </Button>
            <Button
              variant="outlined"
              // className="m-1"
              sx={{ color: "#000", margin: "5px", display: "none" }}
              onClick={() => setOpenChangeAvailabilityAccorOtaDialog(true)}
            >
              {t("Availability According To OTA")}
              <EventAvailableIcon className="ml-1" sx={{ fontSize: "18px" }} />
            </Button>
            <Button
              variant="outlined"
              // className="m-1"
              sx={{ color: "#000", margin: "5px" }}
              onClick={() => setOpenRemoveRatesAndAvailabilityDialog(true)}
            >
              {t("Remove Rates & Availability Rules")}
              <RemoveCircleOutlineIcon
                className="ml-1"
                sx={{ fontSize: "18px" }}
              />
            </Button>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "15px",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Grid container spacing={2} sx={{ width: "40%" }}>
            <Grid item lg={12} md={12}>
              <Typography
                id={"font-600"}
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {t("Select OTA")}
              </Typography>
              <FormControl sx={{ m: 0, marginRight: "10px" }} size="small">
                <InputLabel
                  id="test-select-label"
                  style={{
                    color: "#1E1E1E",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {t("OTA")}
                </InputLabel>
                <Select
                  value={ota}
                  onChange={handleChangeOta}
                  fullWidth
                  style={{ height: "auto" }}
                  multiple
                  MenuProps={{
                    PaperProps: {
                      style: {
                        height: "fit-content",
                      },
                    },
                  }}
                  className="multi-select-height"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  label={t("OTA")}
                  labelId="test-select-label"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected?.length === 1 && selected[0] === 0 && (
                        <p>{t("Select OTA")}</p>
                      )}
                      {selected?.map((value) => {
                        return (
                          <React.Fragment key={value}>
                            {value !== 0 && (
                              <Chip
                                key={value}
                                label={
                                  (otaCodeList?.filter(
                                    (item) => item?.value === value
                                  ))[0].name
                                }
                                clickable
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                                onDelete={(e) => handleDeleteOta(e, value)}
                                onClick={() => console.log("clicked chip")}
                              />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  )}
                >
                  {otaCodeList?.map(
                    (item) =>
                      item?.value !== 0 && (
                        <MenuItem key={item.value} value={item.value}>
                          {item?.name}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Button
              variant="outlined"
              // className="m-1"
              sx={{
                border: "1px solid #BFBFBF",
                marginRight: "10px",
                padding: "7px",
                borderRadius: "12px",
              }}
              onClick={() => handleGetAllBookingRate(arg)}
            >
              <SyncIcon sx={{ fontSize: "18px" }} />
            </Button>
            <Grid container spacing={2} sx={{ width: "fit-content" }}>
              <Grid item lg={12} md={12} style={{ marginRight: "10px" }}>
                <FormControl size="small">
                  <TextField
                    size="small"
                    type="date"
                    sx={{
                      width: "fit-content",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px !important",
                      },
                    }}
                    inputProps={{
                      style: {
                        padding: "3px",
                      },
                    }}
                    value={calenderView?.initialDate}
                    onChange={(e) =>
                      calenderViewChange({
                        ...calenderView,
                        initialDate: moment(e?.target?.value).format(
                          "YYYY-MM-DD"
                        ),
                      })
                    }
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl sx={{ m: 0, marginRight: "10px" }} size="small">
              <Select
                value={calenderView?.perMonth}
                onChange={(e) =>
                  calenderViewChange({
                    ...calenderView,
                    perMonth: e.target.value,
                  })
                }
                sx={{ height: "34px", borderRadius: "12px" }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={7}>{t("For One Week")}</MenuItem>
                <MenuItem value={14}>{t("For Two weeks")}</MenuItem>
                <MenuItem value={31}>{t("For One Month")}</MenuItem>
                <MenuItem value={60}>{t("For Two Month")}</MenuItem>
                <MenuItem value={88}>{t("For Three Months")}</MenuItem>
              </Select>
              {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
            <Button
              size="large"
              variant="contained"
              disableElevation
              className="comman-button"
              color="primary"
              style={{ display: "none" }}
              onClick={() => exportRates()}
            >
              {t("Export Rates")}
            </Button>

            {/* <Button
            size="large"
            variant="contained"
            disableElevation
            // type="submit"
            className="comman-button"
            color="primary"
            // style={{
            //   justifyContent: "center",
            //   width: "fit-content",
            //   // height: "35px",
            //   fontSize: "15px",
            //   padding: "10px",
            //   // paddingRight: "10px",
            //   marginLeft: "auto",
            //   textTransform: "capitalize",
            //   background: "#1C3A66",
            //   borderRadius: "4px",
            //   marginRight: "10px",
            //   // display:"none"
            // }}
            onClick={() => setOpenWebRezPro(true)}
          >
            Import Central Rates
          </Button> */}
          </Box>
        </Box>
        <div className="mt-3">
          {/* <div className="mt-3"> */}
          {/* {loading && <Loader />} */}
          <FullCalendar
            ref={calendarRef}
            initialDate={calenderView?.initialDate}
            key={calenderView?.initialDate}
            schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"} // Will need to purchase a license
            plugins={[
              interactionPlugin,
              resourceTimelinePlugin,
              multiMonthPlugin,
              dayGridPlugin,
            ]}
            locale={localStorage.getItem("lng") || "en"}
            locales={allLocales}
            headerToolbar={{
              right: "today prev,next customButton", // Add 'customButton' to the left side
              left: "title",
            }}
            customButtons={{
              customButton: {
                text: t("Import Central Rates"),
                click: () => setOpenWebRezPro(true),
              },
            }}
            eventClick={handleEventClick}
            // timeZone="UTC"
            events={eventss}
            eventContent={renderEventContent}
            resources={resources}
            resourceGroupField="groupId"
            resourceAreaColumns={columns}
            initialView="resourceTimelineWeek"
            views={{
              resourceTimelineWeek: {
                duration: { days: calenderView?.perMonth },
                slotDuration: "24:00:00",
                slotLabelFormat: [
                  { weekday: "short", day: "2-digit", month: "short" },
                ],
                titleFormat: { day: "2-digit", month: "short" },
              },
            }}
            slotLabelContent={(slotInfo) => {
              const dayOfWeek = slotInfo.date.toLocaleString(
                localStorage.getItem("lng") || "en",
                {
                  weekday: "short",
                }
              );
              const dayOfMonth = slotInfo.date.toLocaleString(
                localStorage.getItem("lng") || "en",
                {
                  day: "numeric",
                }
              );
              const month = slotInfo.date.toLocaleString(
                localStorage.getItem("lng") || "en",
                {
                  month: "short",
                }
              );

              return (
                <div style={{ lineHeight: "18px", paddingInline: "15px" }}>
                  <div>{dayOfWeek}</div>
                  <div>{dayOfMonth}</div>
                  <div>{month}</div>
                </div>
              );
            }}
            datesSet={(argO) => {
              if (
                !(
                  argO?.startStr === arg?.startStr &&
                  argO?.endStr === arg?.endStr
                )
              ) {
                setArg(argO);
                calenderViewChange({
                  ...calenderView,
                  initialDate: moment(argO?.startStr).format("YYYY-MM-DD"),
                });
              }
            }}
            sx={{
              "& .fc-timeline-event": {
                padding: "0px",
              },
            }}
            dragScroll
            resourceAreaWidth="42%"
            height={"auto"}
          />
        </div>

        {/* update spacific day rate */}

        <DialogForm
          scroll="paper"
          width="fit-content"
          title={`${t("Update Rate for")} ${editedEventStart}`}
          openDialog={openDayRateChange}
          handleDialogClose={handleCancelEdit}
          bodyContent={
            <div className="p-2">
              <Grid container spacing={2} alignItems="flex-start">
                {editedEvent?.extendedProps?.rate_rule_type !== 0 && (
                  <Grid item sm={12} md={12} xs={12}>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "justify",
                        verticalAlign: "text-top",
                        alignItems: "center",
                        marginLeft: "-10px",
                      }}
                    >
                      <Checkbox
                        onChange={(e) => setRemoveRates(e.target.checked)}
                      />
                      <Typography className="checkboox_countent ">
                        {t("Remove Rate's Rule.")}
                        {/* {terms?'true':'false'} */}
                      </Typography>
                    </div>
                  </Grid>
                )}
                {!removeRates && (
                  <Grid item sm={12} md={12} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "11px",
                        color: "rgb(30, 30, 30)",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {t("Min Occupancy Rate")}
                    </Typography>
                    <TextField
                      onChange={(e) => {
                        setEditedEventTitle(e.target.value);
                      }}
                      value={editedEventTitle}
                      variant="outlined"
                      type="number"
                      placeholder={t("Enter Amount")}
                    />
                  </Grid>
                )}
                {/* <Grid item sm={4} md={4}>
                  <Typography
                    style={{
                      marginBottom: "11px",
                      color: "rgb(30, 30, 30)",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Extra Adult
                  </Typography>
                  <TextField
                    onChange={(e) =>
                      setAdultRate((item) => ({
                        ...item,
                        rate: e.target.value,
                      }))
                    }
                    value={adultRate?.rate}
                    variant="outlined"
                    type="number"
                    placeholder={t("Enter Amount")}
                    
                  />
                </Grid>
                <Grid item sm={4} md={4}>
                  <Typography
                    style={{
                      marginBottom: "11px",
                      color: "rgb(30, 30, 30)",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Child Rate
                  </Typography>
                  <TextField
                    onChange={(e) =>
                      setChildRate((item) => ({
                        ...item,
                        rate: e.target.value,
                      }))
                    }
                    value={childRate?.rate}
                    variant="outlined"
                    type="number"
                    placeholder={t("Enter Amount")}
                    
                  />
                </Grid> */}
                <Grid item sm={12} md={12} xs={12}>
                  <Button
                    className="mt-1 comman-button"
                    size="large"
                    variant="contained"
                    onClick={handleSaveEvent}
                    disableElevation
                    color="primary"
                    // style={{
                    //   justifyContent: "center",
                    //   width: "98px",
                    //   height: "44px",
                    //   textTransform: "capitalize",
                    //   background:
                    //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                    // }}
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
        />

        {/* update spacific day inventory */}

        <DialogForm
          scroll="paper"
          maxWidth="sm"
          title={`${t("Update Inventory for")} ${editedEventStart}`}
          openDialog={openDayInventoryChange}
          handleDialogClose={handleCancelInventoryEdit}
          bodyContent={
            <div className="p-2">
              <Grid container spacing={2} alignItems="flex-start">
                {editedEvent?.extendedProps?.inventory_rule_type !== 0 && (
                  <Grid item sm={12} md={12} xs={12}>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "justify",
                        verticalAlign: "text-top",
                        alignItems: "center",
                        marginLeft: "-10px",
                      }}
                    >
                      <Checkbox
                        onChange={(e) => setRemoveRates(e.target.checked)}
                      />
                      <Typography className="checkboox_countent ">
                        {t("Remove Inventory's Rule.")}
                        {/* {terms?'true':'false'} */}
                      </Typography>
                    </div>
                  </Grid>
                )}
                {!removeRates && (
                  <Grid item sm={12} md={12} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "11px",
                        color: "rgb(30, 30, 30)",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {t("Inventory")}
                    </Typography>
                    <TextField
                      onChange={(e) => {
                        setEditedEventTitle(e.target.value);
                      }}
                      value={editedEventTitle}
                      variant="outlined"
                      type="number"
                      placeholder={t("Enter Inventory")}
                    />
                  </Grid>
                )}
                <Grid item sm={12} md={12} xs={12}>
                  <Button
                    className="mt-md-1 comman-button"
                    size="large"
                    variant="contained"
                    onClick={handleSaveInventoryEvent}
                    disableElevation
                    color="primary"
                    // style={{
                    //   justifyContent: "center",
                    //   width: "98px",
                    //   height: "44px",
                    //   textTransform: "capitalize",
                    //   background:
                    //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                    // }}
                  >
                    {t("Submit")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
        />

        <DialogForm
          scroll="paper"
          maxWidth="lg"
          // maxWidth="mb"
          title={t("Create Rates And Availbility")}
          openDialog={openChangeRatesAndAvailabilityDialog}
          handleDialogClose={handleRatesAndAvailabilityDialog}
          bodyContent={
            <CreateRatesAndAvailability
              handleRatesAndAvailabilityDialog={
                handleRatesAndAvailabilityDialog
              }
            />
          }
        />

        <DialogForm
          scroll="paper"
          maxWidth="lg"
          // maxWidth="mb"
          title={t("Create Rates According To OTA")}
          openDialog={openChangeRatesAccordingOtaDialog}
          handleDialogClose={handleRatesAccordingOtaDialog}
          bodyContent={
            <RateAccordingToOtas
              handleRatesAccordingOtaDialog={handleRatesAccordingOtaDialog}
            />
          }
        />

        <DialogForm
          scroll="paper"
          maxWidth="lg"
          // maxWidth="mb"
          title={t("Create Availability According To OTA")}
          openDialog={openChangeAvailabilityAccorOtaDialog}
          handleDialogClose={handleAvailabilityAccorOtaDialog}
          bodyContent={
            <AvailabilityAccordingToOtas
              handleAvailabilityAccorOtaDialog={
                handleAvailabilityAccorOtaDialog
              }
            />
          }
        />

        {/* Remove Rates and Availability */}

        <DialogForm
          scroll="paper"
          maxWidth="lg"
          // maxWidth="mb"
          title={t("Remove Rates and Availability Rules")}
          openDialog={openRemoveRatesAndAvailabilityDialog}
          handleDialogClose={handleRemoveRatesAndAvailabilityDialog}
          bodyContent={
            <RemoveRatesAndAvailability
              handleRemoveRatesAndAvailabilityDialog={
                handleRemoveRatesAndAvailabilityDialog
              }
            />
          }
        />

        {/* import Min Stay bulk update */}
        <DialogForm
          scroll="paper"
          maxWidth="lg"
          title={t("Import Min Stay")}
          openDialog={openMinStayUpdateDialog}
          handleDialogClose={() => setOpenMinStayUpdateDialog(false)}
          bodyContent={
            <div className=" pl-2 pr-2 pb-2 mt-3" style={{ height: "auto" }}>
              <div className="tab-heading ">{t("Import Min Stay")}</div>
              <Formik
                initialValues={initialState_minStay}
                onSubmit={async (values, { resetForm }) =>
                  handleMinStaySubmit(values, resetForm)
                }
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={() => ImportMinStayValidator(t)}
              >
                {(props) => {
                  const { handleSubmit } = props;
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography
                            id="font-600"
                            style={{
                              marginBottom: "11px",
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                            }}
                          >
                            {t("Rate Plan ID")}
                          </Typography>
                          <Field
                            label={t("Rate Plan ID")}
                            placeholder={t("Rate Plan ID")}
                            name={`rateid`}
                            id={`rateid`}
                            component={FormikSelect}
                            options={roomRateIdData?.map((data) => ({
                              title: data?.name,
                              value: data?.value,
                            }))}
                          />
                          <ErrorMessage
                            name={`rateid`}
                            component={ShowInputError}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12} sx={{ paddingX: 2 }}>
                          <Typography
                            id="font-600"
                            style={{
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                            }}
                          >
                            {t("Upload Min Stay (Csv File)")} *
                          </Typography>
                          <FormControl fullWidth>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="id_proof"
                              type="file"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "id_proof",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <ErrorMessage
                              name="id_proof"
                              render={renderError}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginRight: "20px",
                          marginBottom: "20px",
                          borderTop: "2px solid",
                          marginTop: "20px",
                        }}
                      >
                        <Grid item xs={2}>
                          <Button
                            className="mt-1 comman-button"
                            size="large"
                            variant="contained"
                            type="submit"
                            color="primary"
                          >
                            {t("Save")}
                          </Button>
                        </Grid>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          }
        />

        {/* import ota rates  */}
        <DialogForm
          scroll="paper"
          maxWidth="lg"
          title={t("Import Central Rates From WebRezPro")}
          openDialog={openWebRezPro}
          handleDialogClose={() => setOpenWebRezPro(false)}
          bodyContent={
            <div className=" pl-2 pr-2 pb-2 mt-3" style={{ height: "auto" }}>
              <div className="tab-heading ">
                {t("Import OTA Rates From WebRezPro")}
              </div>
              <Formik
                initialValues={initialState_WebRezPro}
                onSubmit={async (values, { resetForm }) =>
                  handleSubmit(values, resetForm)
                }
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={() => ImportOtaBookingValidator(t)}
              >
                {(props) => {
                  const { handleSubmit } = props;
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={6} md={6}>
                        <Typography
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                          }}
                        >
                          {t("Select Ota *")}
                        </Typography>
                        <Field
                          label={t("Ota Code")}
                          name="ota_code"
                          options={otaCodeList?.map((type) => ({
                            title: type.name,
                            value: type.value,
                          }))}
                          // defaultOption
                          component={FormikSelect}
                        />
                        <ErrorMessage
                          name="ota_code"
                          component={ShowInputError}
                        />
                      </Grid> */}
                        <Grid item xs={6} md={6}>
                          <Typography
                            id="font-600"
                            style={{
                              marginBottom: "11px",
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                            }}
                          >
                            {t("Rate Plan ID")}
                          </Typography>
                          <Field
                            label={t("Rate Plan ID")}
                            placeholder={t("Rate Plan ID")}
                            name={`rateid`}
                            id={`rateid`}
                            component={FormikSelect}
                            // defaultOption
                            options={roomRateIdData?.map((data) => ({
                              title: data?.name,
                              value: data?.value,
                            }))}
                          />
                          <ErrorMessage
                            name={`rateid`}
                            component={ShowInputError}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sx={{ paddingX: 2 }}>
                          <Typography
                            id="font-600"
                            style={{
                              // marginBottom: "11px",
                              color: "rgb(30, 30, 30)",
                              fontSize: "16px",
                            }}
                          >
                            {t("Upload Room Rates (Csv File)")} *
                          </Typography>
                          <FormControl
                            fullWidth
                            //   className={controlClassName}
                            //   sx={sx}
                          >
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="id_proof"
                              type="file"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "id_proof",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <ErrorMessage
                              name="id_proof"
                              render={renderError}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginRight: "20px",
                          marginBottom: "20px",
                          borderTop: "2px solid",
                          marginTop: "20px",
                        }}
                      >
                        <Grid item xs={2}>
                          <Button
                            className="mt-1 comman-button"
                            size="large"
                            variant="contained"
                            type="submit"
                            color="primary"
                            // style={{
                            //   justifyContent: "center",
                            //   width: "98px",
                            //   height: "44px",
                            //   textTransform: "capitalize",
                            //   background:
                            //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                            // }}
                          >
                            {t("Save")}
                          </Button>
                        </Grid>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    roomCategoryTypes: state.roomRate.roomCategoryTypes,
    calenderView: state?.filters?.otaOverviewCalender,
    ota: state?.filters?.otaOverviewCalender?.ota,
    otaCodeList: state?.filters?.otaOverviewCalender?.otaCodeList,
    hotelID: state?.roomRate?.propertyId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    roomCategoryTypesSuccess: (data) =>
      dispatch(roomCategoryTypesSuccess(data)),
    roomCategoryTypesFailed: () => dispatch(roomCategoryTypesFailed()),
    getLoadingSuccess: () => dispatch(getLoadingSuccess()),
    getLoadingFailed: () => dispatch(getLoadingFailed()),
    propertyIdFailed: () => dispatch(propertyIdFailed()),
    propertyIdSuccess: (data) => dispatch(propertyIdSuccess(data)),
    calenderViewChange: (data) => dispatch(otaOverviewCalenderChange(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OtaOverview);
// export default OtaOverview
